var render = function render(){
  var _vm$items;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return ((_vm$items = _vm.items) === null || _vm$items === void 0 ? void 0 : _vm$items.length) > 0 ? _c('div', {
    staticClass: "b-table__navbar"
  }, [_c('img', {
    staticClass: "cursor",
    attrs: {
      "src": "/img/icons/table/icon_checked.svg",
      "alt": ""
    },
    on: {
      "click": _vm.clear_selected
    }
  }), _vm._v(" " + _vm._s(_vm.items.length) + " Товаров "), _c('div', {
    staticClass: "b-btns"
  }, [_c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.pay
    }
  }, [_vm._v(" Оплатить ")]), _c('b-button', {
    attrs: {
      "variant": "dark"
    },
    on: {
      "click": _vm.removePayment
    }
  }, [_vm._v(" Снять оплату ")])], 1)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }