import { BranchModel } from '@/models/branch.model'
import { OperationModel } from '@/models/operation.model'
import { StorageModel } from '@/models/storage.model'
import { UserModel } from '@/models/user.model'
import { EntityModel } from '@/models/entity.model'
import { PaymentSpecificationPaginationModel } from '@/views/payment/models/payment-spec-pagination.model'
import { AccountModel } from '@/models/account.model'

export class PaymentHeadModel {
  constructor(request = {}) {
    this.branch = new BranchModel(request?.branch)
    this.comment = request?.comment ?? ''
    this.date = request?.date ? new Date(request?.date) : new Date()
    this.fixed = request?.fixed
    this.type = request?.type
    this.id = request?.id
    this.name = request?.name
    this.number = request?.number
    this.operation = request?.operation
    this.owner = new UserModel(request?.owner)
    this.specs = new PaymentSpecificationPaginationModel(request?.specs)
    this.retail_sum = request?.retail_sum
    this.storage = new StorageModel(request?.storage)
    this.sum = request?.sum
    this.entity = new EntityModel(request?.entity)
    this.supplier = new EntityModel(request?.supplier)
    this.entity_acc = new AccountModel(request?.entity_acc)
    this.supplier_acc = new AccountModel(request?.supplier_acc)
    this.pay_num = request.pay_num
    this.pay_date = new Date(request.pay_date)
    this.purpose = request.purpose
    this.comment = request.comment
    this.payed_sum = request.payed_sum
    this.need_sum = request.need_sum
    this.cfu = request.cfu
    this.item_id = request.item_id
  }

  setStorage = (val) => {
    this.storage = val
  }

  setEntity = (val) => {
    this.entity = val
  }

  setSupplier = (val) => {
    this.supplier = val
  }

  setOperation = (val) => {
    this.operation = new OperationModel(val)
  }

  setDate = (val) => {
    this.date = new Date(val)
  }

  setSupplierDocumentId = (val) => {
    this.supplierDocument.id = val
  }
  setSupplierDocumentDate = (val) => {
    this.supplierDocument.date = new Date(val)
  }

  setComment = (val) => {
    this.comment = val
  }
  get input() {
    return {
      branch: this.branch?.id,
      comment: this.comment,
      date: this.date,
      id: this.id,
      name: this.name,
      entity: this?.entity?.id,
      entity_acc: this?.entity_acc?.id,
      supplier_acc: this?.supplier_acc?.id,
      supplier: this.supplier.id,
      operation: this.operation,
      pay_num: this.pay_num,
      pay_date: this.pay_date,
      cfu: this.cfu,
      item_id: this.item_id,
      sum: this.sum
    }
  }
}
