<template>
  <div class="payment-spec-filters">
    <b-form-input
      type="text"
      class="filter-search"
      placeholder="Поиск"
      :is-keyup="true"
      @input="search"
    />
    <!--    <e-select class="filter-search" />-->
    <!--    <e-select-->
    <!--      class="filter-search"-->
    <!--      :options="date_options" />-->
    <b-button
      class="btn-more"
      @click="openSortModal"
    >
      <img
        src="/img/icons/settings.svg"
        alt=""
      />
    </b-button>
    <div class="ml-auto d-flex align-items-center">
      <div class="mr-3 d-flex align-items-center">
        <div class="mr-2">Сумма:</div>
        <e-input
          v-model="sum"
          :disabled="document.fixed || document.type !== 'entersight'"
          style="width: 100px"
          placeholder="0"
        />
      </div>
      <div class="mr-3">
        К оплате: <span class="text-pay-need">{{ formatPrice(document.need_sum) }} ₽</span>
      </div>
      <div class="mr-3">
        Оплачено: <span class="text-payed">{{ formatPrice(document.payed_sum) }} ₽</span>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'PaymentSpecFilters',
    data() {
      return {
        date_options: [
          { id: 'before', name: 'К оплате по графику' },
          { id: 'all', name: 'К оплате по всем документам' }
        ],
        timer: null,
        sum: 0
      }
    },
    watch: {
      'document.sum'() {
        if (this.sum === 0) {
          this.sum = this.document.sum
        }
      },
      sum() {
        this.updateSum(this.sum)
      }
    },
    computed: {
      ...mapGetters({
        document: 'payment/getDocument'
      })
    },
    methods: {
      ...mapActions({
        updateDocument: 'payment/updateDocument'
      }),
      openSortModal() {
        this.$bvModal.show('sort-table-modal')
      },
      updateSum(value) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.updateDocument({ sum: +value })
          this.$emit('update')
        }, 300)
      },
      search(value) {
        this.$emit('search', value)
      },
      formatPrice(summ = 0) {
        const val = summ === null ? 0 : summ

        const formatter = new Intl.NumberFormat('ru-RU', {
          style: 'decimal',
          currency: 'RUB',
          maximumFractionDigits: 2,
          minimumFractionDigits: 0
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          // maximumFractionDigits: 0 // (causes 2500.99 to be printed as $2,501)
        })

        return formatter.format(val)
      }
    }
  }
</script>

<style scoped lang="scss">
  .payment-spec-filters {
    padding: 16px;
    display: flex;
  }

  .filter-search {
    width: 160px !important;
    margin-right: 16px;
  }

  .text-pay-need {
    color: #e53835;
  }

  .text-payed {
    color: #00cb91;
  }
</style>
