import { OutgoingSpecificationModel } from '@/views/operational-processes/models/outgoing-spec.model'

export class OutgoingSpecificationPaginationModel {
  constructor(request = {}) {
    this.avgMarkup = request?.avgMarkup
    this.factTotal = request?.factTotal
    this.requestTotal = request?.requestTotal
    this.specification = request?.specification?.map((el) => new OutgoingSpecificationModel(el)) ?? []
    this.sumTotal = request?.sumTotal
    this.taxRateSum = request?.taxRateSum
    this.total = request?.total
  }
  addSpec(spec) {
    this.specification.push(new OutgoingSpecificationModel(spec))
  }
  addTotal(val) {
    this.total += val
  }
}
