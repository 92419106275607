import { render, staticRenderFns } from "./PaymentHead.vue?vue&type=template&id=767d3028&scoped=true&"
import script from "./PaymentHead.vue?vue&type=script&lang=js&"
export * from "./PaymentHead.vue?vue&type=script&lang=js&"
import style0 from "./PaymentHead.vue?vue&type=style&index=0&id=767d3028&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "767d3028",
  null
  
)

export default component.exports