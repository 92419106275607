<template>
  <div
    v-if="items?.length > 0"
    class="b-table__navbar"
  >
    <img
      src="/img/icons/table/icon_checked.svg"
      alt=""
      class="cursor"
      @click="clear_selected"
    />
    {{ items.length }} Товаров
    <div class="b-btns">
      <b-button
        variant="dark"
        @click="pay"
      >
        Оплатить
      </b-button>
      <b-button
        variant="dark"
        @click="removePayment"
      >
        Снять оплату
      </b-button>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'
  export default {
    name: 'PaymentNavbar',
    props: {
      items: {
        type: Array,
        default: () => []
      },
      clear_selected: {
        type: Function,
        default: () => {}
      }
    },

    methods: {
      ...mapActions({
        setPrintActions: 'products/setPrintActions'
      }),
      pay() {
        this.$emit('pay')
      },
      removePayment() {
        this.$emit('removePayment')
      }
    }
  }
</script>
