<template>
  <div class="d-flex flex-column flex-fill">
    <payment-head
      :document="document"
      @onChange="documentChange"
      @makeUsed="makeUsed"
    />
    <div class="table-content d-flex flex-column flex-fill">
      <div class="payment-spec">
        <payment-spec-filters
          @search="(val) => (pagination.search = val)"
          @update="documentChange"
        />
        <resizable-table
          :inside_card="false"
          class="payment-doc-spec-table"
          table_name="payment-table-spec"
          :items="document?.specs?.list"
          :default_fields="fields"
        >
          <template #head_id="data">
            <e-checkbox
              :value="data.value"
              :checked="allSelected"
              @click="selectAll()"
            />
          </template>

          <template #body_id="data">
            <e-checkbox
              position="center"
              :checked="isSelectedItem(data.item.id)"
              @click="selectRow(data.item.id)"
            />
          </template>
          <template #body_need_date="data">
            {{ convertDate(data.value) }}
          </template>
          <template #body_operation="data">
            {{ operation_mapping[data?.value] }}
          </template>
          <template #body_date="data">
            <div>{{ data.item.doc_number }}</div>
            <div class="mt-2">{{ convertDate(data.value) }}</div>
          </template>
          <template #body_branch="data">
            {{ data.value?.name }}
          </template>
          <template #body_sum="data">
            <div class="text-right">{{ formatMoney(data.value) }}</div>
          </template>
          <template #body_need_sum="data">
            <div class="text-right">{{ formatMoney(data.value) }}</div>
          </template>
          <template #body_payed_sum="data">
            <table-row-input
              :value="data.value"
              :fixed="document.fixed"
              symbol="₽"
              :input_id="data.item.id + 'payedSum'"
              @handle-input="(val) => setPayedSum(val, data.item, true)"
              @input="(val) => setPayedSum(val, data.item)"
            />
          </template>
        </resizable-table>
        <payment-navbar
          class="payment-navbar"
          :items="selected"
          :clear_selected="() => (this.selected = [])"
          @pay="remove_items('all')"
          @removePayment="remove_items('none')"
        />
      </div>
      <pagination
        :total="total"
        table_name="money-spec-table"
        @change_pagination="handlerPagination"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import PaymentHead from '@/views/payment/components/PaymentHead.vue'
  import { PaymentHeadModel } from '@/views/payment/models/payment-head.model'
  import DocumentsTableFilterPayment from '@/views/payment/components/DocumentsTableFilterPayment.vue'
  import ResizableTable from '@/components/ResizableTable'
  import PaymentSpecFilters from '@/views/payment/components/PaymentSpecFilters.vue'
  import formatDate from '@/utils/formatDate'
  import TableRowInput from '@/views/operational-processes/components/TableRowInput.vue'
  import PaymentNavbar from '@/views/payment/components/PaymentNavbar.vue'
  import Pagination from '@/views/shared/components/table/Pagination.vue'
  import { operation_mapping } from '@/utils/constants'
  import { formatMoney } from '@/utils/formatMoney'

  export default {
    name: 'PaymentDocsSpec',
    components: {
      Pagination,
      PaymentNavbar,
      TableRowInput,
      PaymentSpecFilters,
      DocumentsTableFilterPayment,
      PaymentHead,
      ResizableTable
    },
    data() {
      return {
        total: 0,
        pagination: {
          skip: 0,
          take: 20,
          search: ''
        },
        allSelected: false,
        selected: [],
        operation_mapping: operation_mapping,
        fields: [
          {
            key: 'id',
            label: '',
            checked: true,
            width: 50
          },
          {
            key: 'order',
            label: '№',
            checked: true,
            width: 50
          },
          {
            key: 'date',
            label: 'Дата',
            checked: true,
            width: 100
          },
          {
            key: 'operation',
            label: 'Операция',
            checked: true,
            width: 125
          },
          {
            key: 'need_date',
            label: 'Ожидаемая дата платежа',
            checked: true,
            width: 150
          },
          {
            key: 'sum',
            label: 'Сумма',
            checked: true,
            width: 150
          },
          {
            key: 'need_sum',
            label: 'К оплате, ₽',
            checked: true,
            width: 150
          },
          {
            key: 'payed_sum',
            label: 'Оплачено, ₽',
            checked: true,
            width: 150
          },
          {
            key: 'branch',
            label: 'Магазин',
            checked: true,
            width: 150
          },
          {
            key: 'comment',
            label: 'Назначение',
            checked: true,
            width: 150
          }
        ]
      }
    },
    apollo: {
      MoneyHead: {
        query: require('../gql/MoneyHead.graphql'),
        fetchPolicy: 'no-cache',
        debounce: 500,
        variables() {
          return {
            input: {
              id: this.$route.params?.id
            },
            pagination: {
              skip: this.pagination.skip,
              take: this.pagination.take,
              search: this.pagination.search
            }
          }
        },
        result({ data }) {
          this.setDocument(new PaymentHeadModel(data.MoneyHead || {}))
          this.total = data?.MoneyHead?.specs?.total || 0
          if (this.document.id) {
            this.setBreadcrumbs({ ...this.document, is_go_back: true })
          }
        }
      }
    },
    computed: {
      ...mapGetters({
        document: 'payment/getDocument'
      })
    },
    beforeMount() {
      this.setBreadcrumbs({ is_go_back: true })
    },
    updated() {},
    mounted() {
      this.setDocument(new PaymentHeadModel())
      if (this.document.id) {
        this.setBreadcrumbs({ ...this.document, is_go_back: true })
      }
    },
    beforeDestroy() {
      this.setBreadcrumbs({})
    },
    destroyed() {
      this.setBreadcrumbs({})
    },
    methods: {
      ...mapActions({
        setBreadcrumbs: 'breadcrumbs/set_current',
        setDocument: 'payment/setDocument'
      }),
      async remove_items(pay) {
        await this.$apollo.mutate({
          mutation: require('../gql/BulkUpdateMoneySpec.graphql'),
          variables: {
            input: {
              head: this.document.id,
              ids: this.selected,
              pay: pay
            }
          }
        })
        this.selected = []
        this.$apollo.queries.MoneyHead.refetch()
      },
      async documentChange() {
        if (!this.document?.id) return
        const doc = new PaymentHeadModel(this.document)
        await this.$apollo.mutate({
          mutation: require('../gql/UpdateMoneyHead.graphql'),
          variables: {
            input: {
              ...doc.input
            }
          }
        })
        this.$apollo.queries.MoneyHead.refetch()
      },
      async makeUsed(status, cb) {
        try {
          await this.$apollo.mutate({
            fetchPolicy: 'no-cache',
            mutation: require('../gql/ChangeMoneyHeadStatus.graphql'),
            variables: {
              input: {
                id: this.document.id,
                fixed: status
              }
            }
          })
          this.$apollo.queries.MoneyHead.refetch()
        } catch (e) {
          console.error(e)
        }
        cb()
      },
      formatMoney(value) {
        return formatMoney(value, 2)
      },
      selectAll() {
        this.allSelected = !this.allSelected
        if (this.allSelected) {
          this.selected = this.document?.specs?.list?.map((obj) => obj.id)
        } else {
          this.selected = []
        }
      },
      selectRow(id) {
        if (this.selected.includes(id)) {
          this.selected = this.selected.filter((obj) => obj !== id)
        } else {
          this.selected.push(id)
        }
      },
      isSelectedItem(id) {
        return this.selected.includes(id)
      },
      convertDate(dt) {
        const date = new Date(dt)
        if (date && date instanceof Date && !isNaN(date)) return formatDate(new Date(date), 'numeric')
        else return formatDate(new Date(), 'numeric')
      },
      handlerPagination({ current_page = null, take = null }) {
        if (current_page) this.pagination.skip = Number((current_page - 1) * take)
        if (take) this.pagination.take = take
      },
      setPayedSum(value, item) {
        item.payed_sum = value
        this.updateDocs(item)
      },
      updateDocs(spec) {
        this.$apollo.mutate({
          mutation: require('../gql/UpdateMoneySpec.graphql'),
          variables: {
            input: spec.input
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .payment-spec {
    margin-top: 15px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  :deep(.payment-doc-spec-table) .table-docs {
    min-height: 100% !important;
    padding-bottom: 0 !important;
    max-height: 0 !important;

    //thead {
    //  border-top: none !important;
    //}
  }
</style>
