import { IncomingHeadModel } from '@/views/operational-processes/models/incoming-head.model'
import { PackageModel } from '@/models/package.model'
import { OutgoingHeadModel } from '@/views/operational-processes/models/outgoing-head.model'
import { BranchModel } from '@/models/branch.model'
const bigDecimal = require('js-big-decimal')
export class PaymentSpecificationModel {
  constructor(request = {}) {
    this.document_head = this.id = request?.id
    this.inc = new IncomingHeadModel(request?.inc)
    this.out = new OutgoingHeadModel(request?.out)
    this.branch = new BranchModel(request?.inc?.branch || request?.out?.branch)
    this.order = request?.order
    this.operation = request?.inc?.operation?.name || request?.out?.operation?.name
    this.doc_number = request?.inc?.number || request?.out?.number
    this.sum = request?.sum
    this.need_sum = request?.need_sum
    this.need_date = request?.date
    this.payed_sum = request?.payed_sum
    this.date = request?.inc?.date || request?.out?.date
  }

  setSum = (val) => {
    this.sum = val
    this.price = Number((this.sum / (this.delta * (this.packages?.value ?? 1))).toFixed(2))
  }

  setPackage = (val) => {
    if (val.id === '') this.packages = null
    this.packages = new PackageModel(val)
    this.setSum(this.sum)
    this.retailPrice = Math.floor((this.retailPrice / (this.packages?.value ?? 1)) * 100) / 100
  }

  setPrice = (val) => {
    this.price = val
    this.sum = +(this.price * this.delta * (this.packages?.value ?? 1)).toFixed(2)
  }

  setNds = (val) => {
    this.nds = val
  }

  setDelta = (val) => {
    this.delta = val
    this.sum = +(this.price * this.delta * (this.packages?.value ?? 1)).toFixed(2)
  }

  setRetailPrice = (val) => {
    this.retailPrice = val
  }

  setProductionDate = (val) => {
    this.production_date = new Date(val)
  }

  setMarkup = (val) => {
    if (!val) return
    if (val === this.getMarkup()) return
    const round_to = this.product?.category?.markup?.round_to || 'none'
    val = val + 100
    if (round_to === 'none')
      this.retailPrice = +bigDecimal.divide(
        bigDecimal.floor(bigDecimal.multiply(bigDecimal.multiply(this.price, bigDecimal.divide(val, 100, 10)), 100)),
        100,
        10
      )
    else if (round_to === 'half') this.retailPrice = this.round((this.price * (val / 100)).toFixed(2))
    else if (round_to === 'rubles') this.retailPrice = Math.ceil(this.price * (val / 100))
  }

  get taxRateSum() {
    return Number((this.sum * (1 - 1 / (1 + this.nds.value) || 0)).toFixed(2))
  }

  get markup() {
    return this.getMarkup()
  }
  getMarkup() {
    return Number((((this.retailPrice - this.price) / this.price) * 100).toFixed(2))
  }
  get input() {
    return {
      id: this.id ?? null,
      payed_sum: this.payed_sum
    }
  }

  round(number) {
    number *= 100

    number = Math.ceil(number / 50) * 50

    number /= 100

    return number
  }
}
