<template>
  <div>
    <div class="b-table__filter d-flex justify-content-between">
      <div class="b-toggle-button">
        <b-button
          v-b-toggle.collapse-filters
          class="btn-filters cursor"
        >
          Параметры документа
          <img
            class="ml-3"
            src="/img/icons/arrow_up.svg"
            alt=""
          />
        </b-button>
      </div>
      <div class="b-action-buttons">
        <b-button
          v-if="!document.fixed"
          variant="outline-primary"
          class="cursor"
          @click="back"
        >
          Сохранить черновик
        </b-button>
        <b-button
          variant="primary"
          class="cursor ml-3"
          :disabled="isLoading"
          @click="updateDocument(document.fixed)"
        >
          <template v-if="is_request_update">
            <b-spinner
              variant="light"
              small
            />
          </template>
          <template v-else-if="!document.fixed"> Провести </template>
          <template v-else-if="document.fixed">Редактировать</template>
        </b-button>
      </div>
    </div>
    <b-collapse
      id="collapse-filters"
      visible
    >
      <filters-payment
        ref="header_filters_incoming"
        :fixed="document?.fixed"
        :filter_params="document"
        :is_supplier_error="isSupplierError"
        :is_storage_error="isStorageError"
        :is_entity_acc_error="is_entity_acc_error"
        :is_supplier_acc_error="is_supplier_acc_error"
        :is_entity_error="isEntityError"
        @change="change"
      />
    </b-collapse>
  </div>
</template>

<script>
  import { PaymentHeadModel } from '@/views/payment/models/payment-head.model'
  import FiltersPayment from '@/views/payment/components/FiltersPayment.vue'

  export default {
    name: 'PaymentHead',
    components: {
      FiltersPayment
    },
    props: {
      document: {
        type: PaymentHeadModel
      }
    },
    data() {
      return {
        isSupplierError: false,
        isStorageError: false,
        isEntityError: false,
        is_entity_acc_error: false,
        is_supplier_acc_error: false,
        is_request_update: false,
        isLoading: false
      }
    },
    methods: {
      updateDocument(status) {
        this.$emit('update-data')
        this.isLoading = true
        if (!status && !this.document.supplier?.id) {
          this.isSupplierError = true
          this.isLoading = false
          return this.$noty.error('Выберите поставщика')
        }
        if (!status && !this.document.entity?.id) {
          this.isEntityError = true
          this.isLoading = false
          return this.$noty.error('Выберите организацию')
        }
        if (!status && !this.document.entity_acc?.check_acc) {
          this.is_entity_acc_error = true
          this.isLoading = false
          return this.$noty.error('Укажите счет')
        }
        if (!status && !this.document.supplier_acc?.check_acc) {
          this.is_supplier_acc_error = true
          this.isLoading = false
          return this.$noty.error('Укажите счет')
        }
        this.$emit('makeUsed', !status, () => {
          this.is_supplier_acc_error = false
          this.is_entity_acc_error = false
          this.isSupplierError = false
          this.isEntityError = false
          this.isLoading = false
        })
      },
      makeFixed(status) {
        this.$emit('makeUsed', status)
      },
      back() {
        this.$emit('onChange')
        this.$router.back()
      },
      change() {
        if (this.document.supplier_acc?.check_acc) this.is_supplier_acc_error = false
        if (this.document.entity_acc?.check_acc) this.is_entity_acc_error = false
        if (this.document.supplier?.id) this.isSupplierError = false
        if (this.document.storage?.id) this.isStorageError = false
        if (this.document.entity?.id) this.isEntityError = false
        this.$emit('onChange')
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep() {
    .ukd-button {
      background: #e5b509 !important;
      color: white !important;
    }

    .table-filter {
      button {
        background: #efefef;
        color: #313131;
      }
    }

    .b-table__filter {
      .b-toggle-button {
        button {
          background: #e2e2e2;
          color: #313131;
        }
      }
    }
  }

  .ukd-button {
    img {
      transform: none !important;
    }
  }

  .b-table__filter {
    .b-toggle-button {
      button {
        img {
          margin-right: 0;
          transition: all 0.2s;
          transform: rotate(180deg);
        }

        &.not-collapsed {
          img {
            transform: rotate(0);
          }
        }
      }
    }
  }

  .table-filter {
    button {
      &:hover {
        background: #efefef;
        color: #313131;
      }
    }
  }
</style>
