var render = function render(){
  var _vm$document;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "b-table__filter d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "b-toggle-button"
  }, [_c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.collapse-filters",
      modifiers: {
        "collapse-filters": true
      }
    }],
    staticClass: "btn-filters cursor"
  }, [_vm._v(" Параметры документа "), _c('img', {
    staticClass: "ml-3",
    attrs: {
      "src": "/img/icons/arrow_up.svg",
      "alt": ""
    }
  })])], 1), _c('div', {
    staticClass: "b-action-buttons"
  }, [!_vm.document.fixed ? _c('b-button', {
    staticClass: "cursor",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.back
    }
  }, [_vm._v(" Сохранить черновик ")]) : _vm._e(), _c('b-button', {
    staticClass: "cursor ml-3",
    attrs: {
      "variant": "primary",
      "disabled": _vm.isLoading
    },
    on: {
      "click": function ($event) {
        return _vm.updateDocument(_vm.document.fixed);
      }
    }
  }, [_vm.is_request_update ? [_c('b-spinner', {
    attrs: {
      "variant": "light",
      "small": ""
    }
  })] : !_vm.document.fixed ? [_vm._v(" Провести ")] : _vm.document.fixed ? [_vm._v("Редактировать")] : _vm._e()], 2)], 1)]), _c('b-collapse', {
    attrs: {
      "id": "collapse-filters",
      "visible": ""
    }
  }, [_c('filters-payment', {
    ref: "header_filters_incoming",
    attrs: {
      "fixed": (_vm$document = _vm.document) === null || _vm$document === void 0 ? void 0 : _vm$document.fixed,
      "filter_params": _vm.document,
      "is_supplier_error": _vm.isSupplierError,
      "is_storage_error": _vm.isStorageError,
      "is_entity_acc_error": _vm.is_entity_acc_error,
      "is_supplier_acc_error": _vm.is_supplier_acc_error,
      "is_entity_error": _vm.isEntityError
    },
    on: {
      "change": _vm.change
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }