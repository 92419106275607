<template>
  <div class="params-body">
    <add-account @add_account="createSuppAcc" />
    <div class="card mt-3 pb-0 pr-0 mb-0">
      <b-form class="form pr-0">
        <b-row class="w-100 align-items-end">
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Дата"
              label-for="work_period"
            >
              <template v-if="fixed">
                {{ convertDate(filter_params.date) }}
              </template>
              <template v-else>
                <date-picker
                  id="work_period"
                  :clearable="false"
                  class="elm-calendar"
                  placeholder="Выберите дату"
                  :value="filter_params.date"
                  format="dd.MM.yyyy"
                  transfer
                  @on-change="setDate"
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Операции по документу"
              label-for="doc_operations"
            >
              <template v-if="fixed">
                {{ filter_params.operation.name === 'bonus' ? 'Бонус' : 'Приход' }}
              </template>
              <template v-else>
                <i-select
                  id="doc_operations"
                  :value="filter_params.operation"
                  :disabled="fixed"
                  transfer
                  @on-select="setOperation"
                >
                  <i-option
                    v-for="item in operation_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Контрагент"
              label-for="supplier"
              :class="{ error: is_supplier_error }"
              :disabled="disabled"
            >
              <template v-if="fixed">
                {{ filter_params.supplier.name }}
              </template>
              <template v-else>
                <i-select
                  id="supplier"
                  :value="filter_params.supplier?.id"
                  filterable
                  transfer
                  :disabled="disabled"
                  @on-change="setSupplier"
                >
                  <i-option
                    v-for="item in supplier_list"
                    :key="item.id"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </i-option>
                </i-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Счет контрагента"
              label-for="supplier-acc"
              :class="{ error: is_supplier_acc_error }"
            >
              <template v-if="fixed">
                {{ filter_params.supplier_acc.check_acc }}
              </template>
              <template v-else>
                <e-select
                  key-name="check_acc"
                  select_name="mes_select"
                  selected_type="text"
                  position="bottom"
                  :error="is_supplier_acc_error"
                  :value="[filter_params.supplier_acc]"
                  :options="filter_params.supplier.account"
                  :serchable="true"
                  search_in_drop
                  :show_bottom_button="true"
                  :dropdown_width="270"
                  :disabled="!filter_params.supplier?.id"
                  @click_bottom="createAcc(filter_params.supplier?.id, 'supplier_acc')"
                  @change="setSupplierAcc"
                >
                  <template #custom_name="{ item }">
                    <div class="dropdown-bank-info">
                      <div>{{ item.check_acc }}</div>
                      <div class="bank-footer">
                        <span class="bank-footer-first">Банк: {{ item.bank.name }} </span>
                        <span>БИК: {{ item.bank.bic }}</span>
                      </div>
                    </div>
                  </template>
                  <template #bottom_button_text> Банк контрагента</template>
                </e-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="№ платежа"
              label-for="supplier_doc_number"
            >
              <template v-if="fixed">
                {{ filter_params.pay_num }}
              </template>
              <template v-else>
                <b-form-input
                  id="supplier_doc_number"
                  type="text"
                  :value="filter_params.pay_num"
                  :disabled="disabled"
                  :is-keyup="true"
                  @input="setSupplierDocumentId"
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Дата платежа"
              label-for="supplier_data"
            >
              <template v-if="fixed">
                {{ convertDate(filter_params.pay_date) }}
              </template>
              <template v-else>
                <date-picker
                  id="supplier_data"
                  class="elm-calendar"
                  :clearable="false"
                  placeholder="Выберите дату"
                  :value="filter_params.pay_date"
                  format="dd.MM.yyyy"
                  :is-keyup="true"
                  transfer
                  :disabled="disabled"
                  @on-change="setSupplierDate"
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Организация"
              label-for="entity"
              :class="{ error: is_entity_error }"
            >
              <template v-if="fixed">
                {{ filter_params.entity.name }}
              </template>

              <e-select
                v-else
                v-model="active_entity"
                :error="is_entity_error"
                selected_type="text"
                :options="entity_list"
                select_name="entity_select"
                @input="setEntity"
              >
                <e-option
                  v-for="item in entity_list"
                  :key="item.id"
                  :item="item"
                >
                  <div class="entity">
                    <p class="name">{{ item.name }}</p>
                    <div class="inn_kpp">
                      <p class="inn">ИНН: {{ item.inn ? item.inn : '-' }}</p>
                      <p class="kpp">КПП: {{ item.kpp ? item.kpp : '-' }}</p>
                    </div>
                  </div>
                </e-option>
              </e-select>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Счет организации"
              label-for="entity-acc"
              :class="{ error: is_entity_acc_error }"
              :disabled="disabled"
            >
              <template v-if="fixed">
                {{ filter_params.entity_acc.check_acc }}
              </template>
              <template v-else>
                <e-select
                  key-name="check_acc"
                  select_name="mes_select"
                  selected_type="text"
                  position="bottom"
                  :error="is_entity_acc_error"
                  :value="[filter_params.entity_acc]"
                  :options="filter_params.entity.account"
                  :serchable="true"
                  search_in_drop
                  :show_bottom_button="true"
                  :dropdown_width="270"
                  :disabled="!filter_params.entity?.id"
                  @click_bottom="createAcc(filter_params.entity?.id, 'entity_acc')"
                  @change="setEntityAcc"
                >
                  <template #custom_name="{ item }">
                    <div class="dropdown-bank-info">
                      <div>{{ item.check_acc }}</div>
                      <div class="bank-footer">
                        <span class="bank-footer-first">Банк: {{ item.bank.name }} </span>
                        <span>БИК: {{ item.bank.bic }}</span>
                      </div>
                    </div>
                  </template>
                  <template #bottom_button_text> Банк контрагента</template>
                </e-select>
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="ЦФУ"
              label-for="entity-acc"
              :class="{ error: is_entity_acc_error }"
              :disabled="disabled"
            >
              <template v-if="fixed">
                {{ getCfuName(filter_params.cfu) }}
              </template>
              <template v-else>
                <e-select
                  key-name="name"
                  select_name="mes_select"
                  selected_type="text"
                  position="bottom"
                  :error="is_entity_acc_error"
                  :value="[{ id: filter_params.cfu }]"
                  :options="budget_cfu"
                  :serchable="true"
                  search_in_drop
                  :dropdown_width="270"
                  @change="setCfu"
                />
              </template>
            </b-form-group>
          </b-col>
          <b-col
            xl="2"
            lg="4"
            md="4"
            sm="6"
            class="pr-0"
          >
            <b-form-group
              label="Статья"
              label-for="budget_items"
              :class="{ error: is_entity_acc_error }"
              :disabled="disabled"
            >
              <template v-if="fixed">
                {{ getItemName(filter_params.item_id) }}
              </template>
              <template v-else>
                <e-select
                  key-name="name"
                  select_name="mes_select"
                  selected_type="text"
                  position="bottom"
                  :error="is_entity_acc_error"
                  :value="[{ id: filter_params.item_id }]"
                  :options="budget_items"
                  :serchable="true"
                  search_in_drop
                  :dropdown_width="270"
                  @change="setItem"
                />
              </template>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="w-100">
          <b-col>
            <div
              v-b-toggle.collapse-extraparams
              class="btn-extra-param cursor mb-3"
            >
              <span class="isHide">Показать</span>
              <span class="isShow">Скрыть</span>
              дополнительные параметры
            </div>
            <b-collapse
              id="collapse-extraparams"
              class="row mb-3"
            >
              <b-col
                col
                xl="4"
                lg="4"
                md="12"
                class="pr-0"
              >
                <b-form-group
                  label="Назначение"
                  label-for="comment"
                >
                  <b-form-textarea
                    id="comment"
                    :value="filter_params.comment"
                    rows="3"
                    max-rows="6"
                    :is-keyup="true"
                    :disabled="fixed"
                    @input="setComment"
                  />
                </b-form-group>
              </b-col>
            </b-collapse>
          </b-col>
        </b-row>
      </b-form>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/utils/formatDate'
  // import { bus } from '@/main'
  import { mapActions, mapGetters } from 'vuex'
  import { PaymentHeadModel } from '@/views/payment/models/payment-head.model'
  import AddAccount from '@/views/core/modal/AddAccount.vue'

  export default {
    components: { AddAccount },
    props: {
      menu_list: {
        type: Array,
        default: () => []
      },

      filter_params: {
        type: PaymentHeadModel,
        default: null
      },

      fixed: {
        type: Boolean,
        default: false
      },

      is_entity_error: {
        type: Boolean,
        default: false
      },

      is_supplier_error: {
        type: Boolean,
        default: false
      },
      is_storage_error: {
        type: Boolean,
        default: false
      },

      is_entity_acc_error: {
        type: Boolean,
        default: false
      },

      is_supplier_acc_error: {
        type: Boolean,
        default: false
      }
    },

    apollo: {
      ReadBudgetItem: {
        query: require('../gql/ReadBudgetItem.graphql'),
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.budget_items = (data.ReadBudgetItem || []).filter((el) => el.type === 'out')
        }
      },
      ReadBudgetCfu: {
        query: require('../gql/ReadBudgetCfu.graphql'),
        result({ data }) {
          this.budget_cfu = data.ReadBudgetCfu || []
        }
      },
      Suppliers: {
        query: require('../gql/getSuppliers.gql'),
        fetchPolicy: 'no-cache',
        result({ data }) {
          this.supplier_list = data.Suppliers || []
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      },

      Storages: {
        query: require('../gql/getStorages.gql'),
        debounce: 300,
        fetchPolicy: 'no-cache',
        variables() {
          return {
            branch: this.currentBranch.id
          }
        },
        result({ data }) {
          this.storage_list = data.Storages || []
          if (!this.first) {
            if (this.storage_list.length === 1) {
              this.filter_params.setStorage(data.Storages?.[0])
            }
          }
          this.first = false
        },
        error(error) {
          console.error('Ошибка запроса: ', error)
        }
      }
    },

    data: () => ({
      active_entity: [],
      first: true,
      operation_list: [
        {
          name: 'Исходящий платёж',
          id: 'outgoing_cashless',
          type: 'outgoing_cashless'
        },
        {
          name: 'Входящий платёж',
          id: 'incoming_cashless',
          type: 'incoming_cashless'
        }
      ],
      supplier_list: [],
      storage_list: [],
      entity_list: [],
      timer: null,
      currentEntity: null,
      currentEntityType: null,
      budget_items: [],
      budget_cfu: []
    }),

    computed: {
      ...mapGetters({
        currentBranch: 'settings/getCurrentBranch',
        getIncomingDocument: 'operprocess/getIncomingDocument'
      }),
      disabled() {
        return ['mercury', 'crptech'].includes(this.filter_params.edo?.type) || this.fixed
      }
    },

    watch: {
      filter_params() {
        this.$apollo.queries.Storages.refresh()
      },
      'currentBranch.entities'() {
        this.entity_list = this.currentBranch?.entities || []
      }
    },

    mounted() {
      this.entity_list = this.currentBranch?.entities || []
      if (this.filter_params?.entity?.id) {
        this.active_entity = [this.filter_params.entity]
      }
    },

    methods: {
      ...mapActions({
        updateDocument: 'payment/updateDocument'
      }),
      getItemName(id) {
        return this.budget_items.find((el) => el.id === id)?.name
      },
      getCfuName(id) {
        return this.budget_cfu.find((el) => el.id === id)?.name
      },
      createAcc(id, type) {
        this.currentEntity = id
        this.currentEntityType = type
        this.$bvModal.show('add-account-modal')
      },
      changeStorage(id) {
        this.storage_list.filter((item) => {
          if (item.id.includes(id)) this.filter_params.setStorage(item)
        })
        this.$nextTick(() => this.$emit('change'))
      },
      setOperation({ value }) {
        this.updateDocument({ operation: value })
        this.$emit('change')
      },
      setSupplier(val) {
        const supplier = this.supplier_list.find((el) => el.id === val)
        this.updateDocument({ supplier: supplier })
        this.updateDocument({ supplier_acc: null })
        this.$emit('change')
      },
      setEntity() {
        this.updateDocument({ entity: this.active_entity[0] })
        this.updateDocument({ entity_acc: null })
        this.$emit('change')
      },
      setSupplierAcc(val) {
        const acc = this.filter_params.supplier.account.find((el) => el.id === val[0].id)
        this.updateDocument({ supplier_acc: acc })
        this.$emit('change')
        //
      },
      setEntityAcc(val) {
        const acc = this.filter_params.entity.account?.find((el) => el.id === val[0].id)
        this.updateDocument({ entity_acc: acc })
        this.$emit('change')
        //
      },
      setCfu(val) {
        const acc = this.budget_cfu?.find((el) => el.id === val[0].id)
        this.updateDocument({ cfu: acc.id })
        this.$emit('change')
        //
      },
      setItem(val) {
        const acc = this.budget_items?.find((el) => el.id === val[0].id)
        this.updateDocument({ item_id: acc.id })
        this.$emit('change')
        //
      },
      setSupplierDocumentId(val) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.updateDocument({ pay_num: val })
          this.$emit('change')
        }, 500)
      },
      setComment(val) {
        this.filter_params.setComment(val)
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.$emit('change')
        }, 500)
      },
      setDate(val) {
        this.updateDocument({ date: this.toNormalDate(val) })
        this.$emit('change')
      },
      setSupplierDate(val) {
        this.updateDocument({ pay_date: this.toNormalDate(val) })
        this.$emit('change')
      },
      toNormalDate(val) {
        const parts = val.split('.')
        return new Date(parseInt(parts[2], 10), parseInt(parts[1], 10) - 1, parseInt(parts[0], 10))
      },

      convertDate(date) {
        if (date) return formatDate(new Date(date))
      },
      async createSuppAcc(account) {
        const { data } = await this.$apollo.mutate({
          mutation: require('../../contractors/gql/createAccount.gql'),
          variables: {
            input: { ...account, entity: this.currentEntity }
          }
        })
        await this.updateDocument({ [this.currentEntityType]: data.CreateCompanyBankAccount })
        this.$emit('change')
      }
    }
  }
</script>

<style scoped lang="scss">
  ::v-deep() {
    .params-body {
      .form {
        .col-lg-4 {
          margin-bottom: 16px !important;
        }
      }

      .btn-extra-param {
        border-bottom: 1px solid #313131;
        display: inline-block;

        .isShow {
          display: none;
        }

        &.not-collapsed {
          .isHide {
            display: none;
          }

          .isShow {
            display: inline;
          }
        }
      }

      textarea {
        overflow: hidden;
        resize: none;
      }

      @media screen and (max-width: 1290px) and (min-width: 1200px) {
        .col-xl-2 {
          flex: 0 0 33.33333333%;
          max-width: 33.33333333%;
        }
      }
    }
  }

  .dropdown-bank-info {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .bank-footer {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    /* identical to box height, or 200% */

    /* Text/secondary - gray400 */

    color: #888888;

    .bank-footer-first {
      margin-right: 12px;
    }
  }

  .entity {
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    p {
      margin-bottom: 0;
    }

    .name {
      display: flex;
      float: left;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #313131;
    }

    .inn_kpp {
      display: flex;

      .inn {
        margin-right: 5px;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: #888888;
      }
    }
  }

  .params-body {
    .form {
      display: flex;
      align-items: flex-end;
      flex-wrap: wrap;
      gap: 0;
      max-width: 1440px;

      ::v-deep .form-group {
        min-width: 150px;
        margin-bottom: 0;
      }

      .col-lg-4 {
        margin-bottom: 16px !important;
      }

      // @media (max-width: 1200px) {}
    }

    .btn-extra-param {
      border-bottom: 1px solid #313131;
      display: inline-block;

      .isShow {
        display: none;
      }

      &.not-collapsed {
        .isHide {
          display: none;
        }

        .isShow {
          display: inline;
        }
      }
    }

    // #collapse-extraparams {
    //   textarea {
    //     overflow: hidden;
    //     resize: none;
    //   }
    // }

    // @media screen and (max-width: 1290px) and (min-width: 1200px) {
    //   .col-xl-2 {
    //     flex: 0 0 33.33333333%;
    //     max-width: 33.33333333%;
    //   }
    // }
  }
</style>
