import { PaymentSpecificationModel } from '@/views/payment/models/payment-spec.model'

export class PaymentSpecificationPaginationModel {
  constructor(request = {}) {
    this.avgMarkup = request?.avgMarkup
    this.factTotal = request?.factTotal
    this.requestTotal = request?.requestTotal
    this.prev_cursor = request?.prev_cursor
    this.next_cursor = request?.next_cursor
    this.list = request?.list?.map((el) => new PaymentSpecificationModel(el)) ?? []
    this.sumTotal = request?.sumTotal
    this.taxRateSum = request?.taxRateSum
    this.total = request?.total
  }
  addSpec(spec) {
    this.specification.push(new PaymentSpecificationModel(spec))
  }
  addTotal(val) {
    this.total += val
  }

  setTotal(request) {
    this.avgMarkup = request?.avgMarkup
    this.factTotal = request?.factTotal
    this.requestTotal = request?.requestTotal
    this.sumTotal = request?.sumTotal
    this.taxRateSum = request?.taxRateSum
    this.total = request?.total
  }
}
