var render = function render(){
  var _vm$document,
      _vm$document$specs,
      _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-fill"
  }, [_c('payment-head', {
    attrs: {
      "document": _vm.document
    },
    on: {
      "onChange": _vm.documentChange,
      "makeUsed": _vm.makeUsed
    }
  }), _c('div', {
    staticClass: "table-content d-flex flex-column flex-fill"
  }, [_c('div', {
    staticClass: "payment-spec"
  }, [_c('payment-spec-filters', {
    on: {
      "search": function (val) {
        return _vm.pagination.search = val;
      },
      "update": _vm.documentChange
    }
  }), _c('resizable-table', {
    staticClass: "payment-doc-spec-table",
    attrs: {
      "inside_card": false,
      "table_name": "payment-table-spec",
      "items": (_vm$document = _vm.document) === null || _vm$document === void 0 ? void 0 : (_vm$document$specs = _vm$document.specs) === null || _vm$document$specs === void 0 ? void 0 : _vm$document$specs.list,
      "default_fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "head_id",
      fn: function (data) {
        return [_c('e-checkbox', {
          attrs: {
            "value": data.value,
            "checked": _vm.allSelected
          },
          on: {
            "click": function ($event) {
              return _vm.selectAll();
            }
          }
        })];
      }
    }, {
      key: "body_id",
      fn: function (data) {
        return [_c('e-checkbox', {
          attrs: {
            "position": "center",
            "checked": _vm.isSelectedItem(data.item.id)
          },
          on: {
            "click": function ($event) {
              return _vm.selectRow(data.item.id);
            }
          }
        })];
      }
    }, {
      key: "body_need_date",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.convertDate(data.value)) + " ")];
      }
    }, {
      key: "body_operation",
      fn: function (data) {
        return [_vm._v(" " + _vm._s(_vm.operation_mapping[data === null || data === void 0 ? void 0 : data.value]) + " ")];
      }
    }, {
      key: "body_date",
      fn: function (data) {
        return [_c('div', [_vm._v(_vm._s(data.item.doc_number))]), _c('div', {
          staticClass: "mt-2"
        }, [_vm._v(_vm._s(_vm.convertDate(data.value)))])];
      }
    }, {
      key: "body_branch",
      fn: function (data) {
        var _data$value;

        return [_vm._v(" " + _vm._s((_data$value = data.value) === null || _data$value === void 0 ? void 0 : _data$value.name) + " ")];
      }
    }, {
      key: "body_sum",
      fn: function (data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(_vm._s(_vm.formatMoney(data.value)))])];
      }
    }, {
      key: "body_need_sum",
      fn: function (data) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(_vm._s(_vm.formatMoney(data.value)))])];
      }
    }, {
      key: "body_payed_sum",
      fn: function (data) {
        return [_c('table-row-input', {
          attrs: {
            "value": data.value,
            "fixed": _vm.document.fixed,
            "symbol": "₽",
            "input_id": data.item.id + 'payedSum'
          },
          on: {
            "handle-input": function (val) {
              return _vm.setPayedSum(val, data.item, true);
            },
            "input": function (val) {
              return _vm.setPayedSum(val, data.item);
            }
          }
        })];
      }
    }])
  }), _c('payment-navbar', {
    staticClass: "payment-navbar",
    attrs: {
      "items": _vm.selected,
      "clear_selected": function () {
        return _this.selected = [];
      }
    },
    on: {
      "pay": function ($event) {
        return _vm.remove_items('all');
      },
      "removePayment": function ($event) {
        return _vm.remove_items('none');
      }
    }
  })], 1), _c('pagination', {
    attrs: {
      "total": _vm.total,
      "table_name": "money-spec-table"
    },
    on: {
      "change_pagination": _vm.handlerPagination
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }